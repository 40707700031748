import { useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Button, Row, Col, Form, Radio, Select, Image as AntImage, Flex, DatePicker } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form/Form";
import Input from "antd/es/input/Input";
import locale from "antd/es/date-picker/locale/zh_TW";
import { filter } from "lodash";
import numeral from "numeral";

import { RESERVATION_STATE_OPTIONS, VIEWING_TYPE, VIEWING_TYPE_LABEL } from "../../constants/reservation";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {},
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    offset: 6,
  },
};

type ReservationProps = {
  form: FormInstance;
  reservation?: {
    bookingTimes: Dayjs[];
    car: {
      id: number;
      title: string;
      imageUrl: string;
      brand: string;
      modal: string;
      price: number;
      type: string;
      years: number;
    };
    carId: number;
    coordinatorName?: string;
    createdAt: number;
    id: number;
    location?: string;
    merchantId: number;
    name: string;
    phone: string;
    salerId?: string;
    status: "PENDING" | "CONCATED" | "DONE";
    viewingType: "ONLINE" | "OFFLINE";
  };
};

export function ReservationForm(props: ReservationProps) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  const viewingType = Form.useWatch("viewingType", props.form);

  return (
    <Form className="flex flex-row" form={props.form} initialValues={props.reservation || {}}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>

      <Row className="w-full" gutter={15}>
        <Col span={10}>
          {/* <AntImage
            className="w-full mb-2"
            style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
            src={props.reservation?.car.imageUrl}
          /> */}
          <AntImage
            className="w-full mb-2"
            style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
            src={`https://placehold.co/480x480?text=Car+${props.reservation?.car.id}`}
          />

          <div>
            <span className="text-m font-bold">標題：</span>
            {props.reservation?.car.title}
          </div>
          <div>
            <span className="text-m font-bold">車廠：</span>
            {props.reservation?.car.brand}
          </div>
          <div>
            <span className="text-m font-bold">車款：</span>
            {props.reservation?.car.type}
          </div>
          <div>
            <span className="text-m font-bold">車種：</span>
            {props.reservation?.car.modal}
          </div>
          <div>
            <span className="text-m font-bold">價錢：</span>
            {numeral(props.reservation?.car.price).format("$0,0")}
          </div>
          <div>
            <span className="text-m font-bold">出廠年份：</span>
            {props.reservation?.car.years}
          </div>
          <div>
            <span className="text-m font-bold">聯絡資訊：</span>
            {props.reservation?.name} / {props.reservation?.phone}
          </div>
        </Col>

        {/* <Divider /> */}

        <Col span={14}>
          <Form.Item name="status" label="狀態" rules={[{ required: true, message: "請選擇狀態" }]}>
            <Select options={RESERVATION_STATE_OPTIONS} />
          </Form.Item>

          <Form.Item name="viewingType" label="賞車方式" rules={[{ required: true, message: "請選擇賞車方式" }]}>
            <Radio.Group>
              <Radio value={VIEWING_TYPE.ONLINE}>{VIEWING_TYPE_LABEL.ONLINE}</Radio>
              <Radio value={VIEWING_TYPE.OFFLINE}>{VIEWING_TYPE_LABEL.OFFLINE}</Radio>
            </Radio.Group>
          </Form.Item>

          {viewingType === "OFFLINE" && (
            <Form.Item name="location" label="賞車地點" rules={[{ required: true, message: "請輸入地點" }]}>
              <Input />
            </Form.Item>
          )}

          <Form.List
            name="bookingTimes"
            rules={[
              {
                validator: async (_, bookingTimes) => {
                  let excludeEmpty = filter(bookingTimes, time => time);
                  if (!excludeEmpty || excludeEmpty.length === 0) {
                    return Promise.reject(new Error("至少選一個時間"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    key={field.key}
                    label={index === 0 ? "可賞車時間" : ""}
                    required
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  >
                    <Flex align="baseline">
                      <Form.Item {...field}>
                        <DatePicker
                          disabledDate={current => current.isBefore(dayjs())}
                          format="YYYY-MM-DD HH:mm"
                          showTime
                          locale={locale}
                          needConfirm={false}
                        />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button mx-1"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Flex>
                  </Form.Item>
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                    選擇更多時間
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </Form>
  );
}
