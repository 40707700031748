import axios from "axios";
import { useMutation } from "react-query";
import { useAuthStore } from "../../stores/useAuthStore";
import { useMerchantStore } from "../../stores/useMerchantStore";

export function useLogin() {
  const authStore = useAuthStore();

  return useMutation(
    ["login"],
    (data: { token: string }) => {
      return axios.post("/backend/auth/login", data);
    },
    {
      onSuccess: data => {
        authStore.login(data.data.accessToken);
      },
    }
  );
}

export function useAuthInfo() {
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();

  return useMutation(
    ["authInfo"],
    () => {
      return axios.get("/backend/auth/info");
    },
    {
      onSuccess: data => {
        authStore.setAuthInfo(data.data);

        const merchants = data.data?.merchants || [];
        merchantStore.setMerchant(merchants[0].id);
      },
    }
  );
}
