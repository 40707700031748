import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useMerchantStore } from "../../stores/useMerchantStore";

export function useUploadImage() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["imagesList", "create"],
    (data: File[]) => {
      const fData = new FormData();
      data.forEach((element: File) => {
        if (element instanceof File) {
          fData.append("files", element);
        }
      });

      return axios.post(`/backend/merchants/${merchantStore.selectedMerchant}/car-images`, fData);
    },
    {
      onSuccess: () => {
        // message.success("新增成功");
        queryClient.invalidateQueries("imagesList");
      },
    }
  );
}
