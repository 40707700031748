import { useCallback, useState } from "react";
import { Button, Input, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnType } from "antd/es/table";
import useModal from "antd/es/modal/useModal";

import { usePagination } from "../../../hooks/usePagintation";
import { useCreateUser, useUpdateUser, useSuspendUser, useUnsuspendUser, useUsers } from "../../../hooks/apis/user";

import { USER_STATE, USER_STATE_LABEL } from "../../../constants/user";

import { UserForm } from "./UserForm";
import { Tag } from "../../../components/Tag";
import { debounce, map } from "lodash";
import { useAuthStore } from "../../../stores/useAuthStore";

type UserListProps = {
  users: Array<{
    id: number;
    createdAt: number;
    displayName: string;
    email: string;
    roles: [{ id: number; desc: string; name: string }];
    state: string;
    username?: string;
  }>;
};

export function UserList() {
  const pagination = usePagination({
    defaultPageSize: 30,
  });
  const authStore = useAuthStore();
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState({});

  const { data: { users = [], total } = {}, isLoading: isUsersLoading } = useUsers({
    ...filter,
    keyword,
    take: pagination.take,
    skip: pagination.skip,
  });
  const { mutateAsync: createUser, isLoading: isCreateLoading } = useCreateUser();
  const { mutateAsync: updateUser, isLoading: isUpdateLoading } = useUpdateUser();
  const { mutateAsync: suspendUser, isLoading: isSuspendLoading } = useSuspendUser();
  const { mutateAsync: unsuspendUser, isLoading: isUnsuspendLoading } = useUnsuspendUser();

  const [form] = useForm();

  const [modal, contextHolder] = useModal();

  const onEditButtonClick = (user: UserListProps["users"][number]) => {
    modal.confirm({
      cancelText: "取消",
      content: <UserForm user={{ ...user, roleIds: map(user.roles, "id") }} form={form} />,
      icon: null,
      okText: "儲存",
      title: "編輯用戶",
      onOk: async () => {
        const { id, email, username, ...values } = await form.validateFields();

        return updateUser({
          id: user.id,
          ...values,
        });
      },
    });
  };

  const onUnsuspendButtonClick = async (user: UserListProps["users"][number]) => {
    modal.confirm({
      title: "解除停權",
      icon: null,
      content: `確定要解除停權用戶 ${user.displayName} 嗎？`,
      okText: "確定",
      cancelText: "取消",
      onOk: () => {
        return unsuspendUser(user.id);
      },
    });
  };

  const onSuspendButtonClick = async (user: UserListProps["users"][number]) => {
    modal.confirm({
      title: "停權",
      icon: null,
      content: `確定要停權用戶 ${user.displayName} 嗎？`,
      okText: "確定",
      cancelText: "取消",
      onOk: () => {
        return suspendUser(user.id);
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onKeywordInputChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value);
    }, 500),
    [setKeyword]
  );

  const columns: ColumnType<UserListProps["users"][number]>[] = [
    {
      align: "center",
      dataIndex: "state",
      title: "狀態",
      filters: [
        { text: "啟用", value: USER_STATE.ACTIVATED },
        { text: "審核中", value: USER_STATE.REVIEWING },
        { text: "停權", value: USER_STATE.SUSPENDED },
      ],
      width: 100,
      render: (state: USER_STATE) => {
        return (
          <span
            className={`${state === "SUSPENDED" && "text-red-500"} ${state === USER_STATE.REVIEWING && "text-blue-500"}`}
          >
            {USER_STATE_LABEL[state]}
          </span>
        );
      },
    },
    {
      dataIndex: "displayName",
      title: "顯示名稱",
      width: 120,
    },
    {
      dataIndex: "email",
      title: "Email",
      width: 120,
    },
    {
      dataIndex: "username",
      title: "帳號",
      width: 120,
      render: username => {
        return username || "尚未設定";
      },
    },
    {
      dataIndex: "roles",
      title: "角色",
      render: roles => {
        return (
          <div className="flex flex-wrap gap-2">
            {map(roles, role => (
              <Tag title={role.name} key={role.name} />
            ))}
          </div>
        );
      },
    },
    {
      title: "操作",
      width: 150,
      render: user => {
        return (
          <Space size="small">
            <Button disabled={user.isProtected} onClick={() => onEditButtonClick(user)} size="small" type="primary">
              編輯
            </Button>
            {user.state === "SUSPENDED" ? (
              <>
                <Button
                  size="small"
                  type="primary"
                  disabled={user.id === authStore.user.id}
                  onClick={() => onUnsuspendButtonClick(user)}
                >
                  解除停權
                </Button>
              </>
            ) : (
              <Button
                danger
                disabled={user.isProtected || user.id === authStore.user.id}
                size="small"
                type="primary"
                onClick={() => onSuspendButtonClick(user)}
              >
                停權
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: "取消",
      content: <UserForm form={form} />,
      icon: null,
      okText: "確認",
      title: "新增用戶",
      onOk: async () => {
        const values = await form.validateFields();

        return createUser(values);
      },
    });
  };

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">用戶管理</div>

      {contextHolder}

      <Table
        dataSource={users || []}
        columns={columns}
        loading={isUsersLoading || isCreateLoading || isUpdateLoading || isSuspendLoading || isUnsuspendLoading}
        pagination={{ ...pagination, total }}
        scroll={{ x: "max-content" }}
        size="small"
        rowKey="id"
        onChange={(_page, filter) => {
          setFilter(filter);
        }}
        title={() => (
          <div className="flex items-center gap-2 w-80">
            <Button type="primary" onClick={onCreateButtonClick}>
              新增用戶
            </Button>
            <span className="shrink-0">關鍵字</span>
            <Input className="grow" placeholder="請輸顯示名稱、暱稱或是電話號碼" onChange={onKeywordInputChange} />
          </div>
        )}
      ></Table>
    </Space>
  );
}
