import dayjs from "dayjs";
import { filter, map } from "lodash";
import { Button, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnType } from "antd/es/table";
import useModal from "antd/es/modal/useModal";

import { usePagination } from "../../hooks/usePagintation";
import { useReservations, useUpdateReservationDetail } from "../../hooks/apis/reservation";
import { RESERVATION_STATE, RESERVATION_STATE_LABEL, VIEWING_TYPE_LABEL } from "../../constants/reservation";
import { ReservationForm } from "./ReservationForm";

type ReservationListProps = {
  reservations: Array<{
    bookingTimes: number[];
    car: {
      id: number;
      title: string;
      imageUrl: string;
      brand: string;
      modal: string;
      price: number;
      type: string;
      years: number;
    };
    carId: number;
    coordinatorName?: string;
    createdAt: number;
    id: number;
    location?: string;
    merchantId: number;
    name: string;
    phone: string;
    salerId?: string;
    status: "PENDING" | "CONCATED" | "DONE";
    viewingType: "ONLINE" | "OFFLINE";
  }>;
};

export function ReservationList() {
  const pagination = usePagination({
    defaultPageSize: 30,
  });

  const { data: { reservations = [], total } = {}, isLoading: isReservationsLoading } = useReservations({
    take: pagination.take,
    skip: pagination.skip,
  });

  const { mutateAsync: updateReservationDetail, isLoading: isUpdateReservationDetailLoading } =
    useUpdateReservationDetail();

  const [form] = useForm();

  const [modal, contextHolder] = useModal();

  const onEditButtonClick = async (reservation: ReservationListProps["reservations"][number]) => {
    modal.confirm({
      width: 800,
      cancelText: "取消",
      content: (
        <ReservationForm
          reservation={{
            ...reservation,
            bookingTimes: map(reservation.bookingTimes, item => dayjs(item)),
          }}
          form={form}
        />
      ),
      icon: null,
      okText: "儲存",
      title: "編輯預約紀錄",
      onOk: async () => {
        const { bookingTimes, name, phone, ...values } = await form.validateFields();

        const params = {
          ...values,
          bookingTimes: filter(
            map(bookingTimes, item => dayjs(item).set("milliseconds", 0).valueOf()),
            item => !!item
          ),
        };

        return updateReservationDetail(params);
      },
    });
  };

  const columns: ColumnType<ReservationListProps["reservations"][number]>[] = [
    { align: "center", dataIndex: "id", title: "ID", width: 50 },
    {
      align: "center",
      dataIndex: "status",
      title: "狀態",
      width: 70,
      render: (status: RESERVATION_STATE) => {
        return (
          <span
            className={`${status === RESERVATION_STATE.PENDING && "text-red-500"} ${status === RESERVATION_STATE.CONCATED && "text-blue-500"}`}
          >
            {RESERVATION_STATE_LABEL[status]}
          </span>
        );
      },
    },
    {
      align: "center",
      dataIndex: "viewingType",
      title: "賞車方式",
      width: 50,
      render: (viewingType: ReservationListProps["reservations"][number]["viewingType"]) =>
        VIEWING_TYPE_LABEL[viewingType],
    },
    {
      dataIndex: ["car", "title"],
      title: "商品名稱",
      width: 120,
    },
    {
      title: "操作",
      width: 150,
      render: reservation => {
        return (
          <Space size="small">
            <Button onClick={() => onEditButtonClick(reservation)} size="small" type="default">
              編輯
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">預約紀錄管理</div>

      {contextHolder}

      <Table
        dataSource={reservations || []}
        columns={columns}
        loading={isReservationsLoading || isUpdateReservationDetailLoading}
        pagination={{ ...pagination, total }}
        scroll={{ x: "max-content" }}
        size="small"
        rowKey="id"
      ></Table>
    </Space>
  );
}
