import { useMemo } from "react";
import { Button, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnType } from "antd/es/table";
import useModal from "antd/es/modal/useModal";
import numeral from "numeral";

import { usePagination } from "../../hooks/usePagintation";
import {
  useCarBrandList,
  useCarModalList,
  useCarTagList,
  useCreateCarBrand,
  useCreateCarModal,
  useCreateCarTag,
  useCreateCarType,
  useCreateProduct,
  useProductDetail,
  useProducts,
  useUpdateProduct,
} from "../../hooks/apis/product";

import { ProductForm } from "./ProductForm";
import { filter, findIndex, forEach, isArray, isEmpty, map, reduce } from "lodash";
import { useUploadImage } from "../../hooks/apis/upload";
import { useNavigate } from "react-router-dom";

type ProductListProps = {
  products: Array<{
    id: number;
    merchantId: number;
    isSales: boolean;
    brand: {
      id: number;
      name: string;
      types: string[];
    };
    type: string;
    modal: {};
    title: string;
    years: number;
    price: number;
    tags: string[];
    imageUrl: string;
    images: { id: number; isCover: boolean; url: string }[];
    creator: {
      id: number;
      name: {};
      email: string;
    };
  }>;
};

export function ProductList() {
  const navigate = useNavigate();
  const pagination = usePagination({
    defaultPageSize: 30,
  });

  const { data: { cars = [], total } = {}, isLoading: isProductsLoading } = useProducts({
    take: pagination.take,
    skip: pagination.skip,
  });
  const { mutateAsync: getProductDetail } = useProductDetail();
  const { data: { brands = [] } = {}, isLoading: isCarBrandLoading } = useCarBrandList();
  const { data: { modals = [] } = {}, isLoading: isCarModalLoading } = useCarModalList();
  const { data: { tags = [] } = {}, isLoading: isCarTagLoading } = useCarTagList();

  const { mutateAsync: createCarBrand } = useCreateCarBrand();
  const { mutateAsync: createCarType, isLoading: isCreateCarTypeLoading } = useCreateCarType();
  const { mutateAsync: createCarModal, isLoading: isCreateCarModalLoading } = useCreateCarModal();
  const { mutateAsync: createCarTag, isLoading: isCreateCarTagLoading } = useCreateCarTag();
  const { mutateAsync: uploadImage, isLoading: isUploadImageLoading } = useUploadImage();

  const { mutateAsync: createProduct, isLoading: isCreateLoading } = useCreateProduct();
  const { mutateAsync: updateProduct, isLoading: isUpdateLoading } = useUpdateProduct();

  const [form] = useForm();

  const [modal, contextHolder] = useModal();

  const formOptions = useMemo(() => {
    const brand = reduce(
      brands,
      (accumulate: any, item: any) => {
        accumulate[item.id] = item || {};
        return accumulate;
      },
      {}
    );

    return {
      brand,
      modal: map(modals, modal => ({ label: modal, value: modal })),
      tag: map(tags, tag => ({ label: tag, value: tag })),
    };
  }, [brands, modals, tags]);

  const onEditButtonClick = async (product: ProductListProps["products"][number]) => {
    const respProductDetail = (await getProductDetail({ id: product.id }))?.data.car || {};
    const coverImageId = findIndex(respProductDetail.images, (item: { isCover: boolean }) => item.isCover);

    modal.confirm({
      width: 1000,
      cancelText: "取消",
      content: (
        <ProductForm
          product={{
            ...respProductDetail,
            brandId: [String(product.brand.id)],
            type: [product.type],
            modal: [product.modal],
            imageIds: map(respProductDetail.images, item => ({ ...item, uid: item.id })),
            coverImageId: coverImageId !== -1 ? coverImageId + 1 : null,
          }}
          form={form}
          formOptions={formOptions}
        />
      ),
      icon: null,
      okText: "儲存",
      title: "編輯商品",
      onOk: async () => {
        const { type, modal, tags, brandId, coverImageId, imageIds, ...values } = await form.validateFields();

        const params = {
          ...values,
          tags: isEmpty(tags) ? [] : tags,
          type: isArray(type) ? type[0] : type,
          modal: isArray(modal) ? modal[0] : modal,
          brandId: isArray(brandId) ? brandId[0] : brandId,
          imageIds: map(
            filter(imageIds, imageIds => !!imageIds.id),
            "id"
          ),
        };

        const uploadImageList = filter(imageIds, imageIds => !imageIds.id);

        if (uploadImageList.length) {
          const respImageIds = await uploadImage(uploadImageList);
          params.imageIds = params.imageIds.concat(map(respImageIds.data.images || [], "id"));
        }
        params.coverImageId = params.imageIds[coverImageId - 1];

        const isNewBrand =
          filter(Object.values(formOptions.brand), (item: any) => String(item.id) === brandId[0]).length === 0;

        if (isNewBrand && product.brand.id !== brandId[0]) {
          const resp = await createCarBrand({
            brand: brandId[0],
          });
          params.brandId = resp.data.id;
        }

        if (product.type !== params.type) {
          await createCarType({ brandId: params.brandId, type: params.type });
        }

        if (product.modal !== params.modal) {
          await createCarModal({ modal: params.modal });
        }

        forEach(tags, async tag => {
          if (product.tags.indexOf(tag) === -1) {
            await createCarTag({ tag });
          }
        });

        return updateProduct(params);
      },
    });
  };

  const columns: ColumnType<ProductListProps["products"][number]>[] = [
    { align: "center", dataIndex: "id", title: "ID", width: 50 },
    {
      dataIndex: "isSales",
      title: "狀態",
      width: 50,
      render: (isSales: boolean) => (
        <span className={isSales ? "text-green-500" : "text-red-500"}>{isSales ? "上架" : "下架"}</span>
      ),
    },
    {
      dataIndex: ["brand", "name"],
      title: "車廠",
      width: 120,
    },
    {
      dataIndex: "type",
      title: "車款",
      width: 120,
    },
    {
      dataIndex: "modal",
      title: "車種",
      width: 120,
    },
    {
      dataIndex: "price",
      title: "價錢",
      width: 120,
      render: (price: number) => numeral(price).format("$0,0"),
    },
    {
      title: "操作",
      width: 150,
      render: product => {
        return (
          <Space size="small">
            <Button
              disabled={product.isProtected}
              onClick={() => onEditButtonClick(product)}
              size="small"
              type="default"
            >
              編輯
            </Button>
            <Button onClick={() => navigate(`/products/reservation/${product.id}`)} size="small" type="default">
              預約紀錄
            </Button>
          </Space>
        );
      },
    },
  ];

  const onCreateButtonClick = () => {
    modal.confirm({
      width: 1000,
      cancelText: "取消",
      content: <ProductForm form={form} formOptions={formOptions} />,
      icon: null,
      okText: "確認",
      title: "新增商品",
      onOk: async () => {
        const values = await form.validateFields();
        const respImageIds = await uploadImage(values.imageIds);
        const imageIds = map(respImageIds.data.images || [], "id");

        const resp = await createCarBrand({
          brand: values.brandId[0],
        });

        const params = {
          ...values,
          tags: isEmpty(values.tags) ? [] : values.tags,
          type: isArray(values.type) ? values.type[0] : values.type,
          modal: isArray(values.modal) ? values.modal[0] : values.modal,
          brandId: resp.data.id,
          imageIds,
          coverImageId: imageIds[values.coverImageId - 1],
        };

        await createCarType({ brandId: params.brandId, type: params.type });
        await createCarModal({ modal: params.modal });
        forEach(values.tags, async tag => await createCarTag({ tag }));

        return createProduct(params);
      },
    });
  };

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">商品管理</div>

      {contextHolder}

      <Table
        dataSource={cars || []}
        columns={columns}
        loading={
          isProductsLoading ||
          isCreateLoading ||
          isUpdateLoading ||
          isCarBrandLoading ||
          isCarModalLoading ||
          isCarTagLoading ||
          isCreateCarTypeLoading ||
          isCreateCarModalLoading ||
          isCreateCarTagLoading ||
          isUploadImageLoading
        }
        pagination={{ ...pagination, total }}
        scroll={{ x: "max-content" }}
        size="small"
        rowKey="id"
        title={() => (
          <div className="flex items-center gap-2 w-80">
            <Button type="primary" onClick={onCreateButtonClick}>
              新增商品
            </Button>
          </div>
        )}
      ></Table>
    </Space>
  );
}
