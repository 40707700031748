import { useEffect } from "react";
import { Form } from "antd";
import { FormInstance } from "antd/es/form/Form";
import Input from "antd/es/input/Input";
import { RoleSelector } from "../../../components/RoleSelector";

type UserProps = {
  form: FormInstance;
  user?: {
    id?: number;
    createdAt: number;
    displayName: string;
    email: string;
    roles: [{ id: number; desc: string; name: string }];
    state: string;
    username?: string;
    roleIds: number[];
  };
};

export function UserForm(props: UserProps) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} initialValues={props.user || {}}>
      <Form.Item label="帳號" name="username">
        <Input disabled />
      </Form.Item>

      <Form.Item label="顯示名稱" name="displayName" rules={[{ required: true, message: "請輸入顯示名稱" }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Email" name="email" rules={[{ required: true, message: "請輸入Email" }]}>
        <Input disabled={!!props.user?.email} />
      </Form.Item>

      <RoleSelector multiple required />
    </Form>
  );
}
