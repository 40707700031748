import { useAuthStore } from "./stores/useAuthStore";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";

type Props = RouteProps;

export function PrivateRoute(props: Props) {
  const authStore = useAuthStore();
  const { pathname } = useLocation();

  return authStore.token ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      state={{
        from: pathname,
      }}
      replace
    />
  );
}

export function PublicRoute(props: Props) {
  const token = useAuthStore(state => state.token);
  const { state } = useLocation();
  const { from } = state || {};

  return token ? <Navigate to={from ? from : "/"} replace state={{ from: undefined }} /> : <Outlet />;
}
