import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { message } from "antd";
import { useAuthStore } from "../../stores/useAuthStore";
import { useMerchantStore } from "../../stores/useMerchantStore";

export function useProducts(data: { skip?: number; take?: number; keyword?: string }) {
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();

  return useQuery(["products", data, merchantStore.selectedMerchant], async () => {
    if (!authStore.token || !merchantStore.selectedMerchant) return;

    const response = await axios.get<{ cars: []; total: number }>(
      `/backend/merchants/${merchantStore.selectedMerchant}/cars`,
      {
        params: data,
      }
    );

    return response.data;
  });
}

export function useProductDetail() {
  const merchantStore = useMerchantStore();

  return useMutation(
    ["productDetail", merchantStore.selectedMerchant],
    (data: { id: number | string }) => {
      return axios.get(`/backend/merchants/${merchantStore.selectedMerchant}/cars/${data.id}`);
    },
    {
      onSuccess: () => {
        // message.success("新增成功");
        // queryClient.invalidateQueries("carModalList");
      },
    }
  );
}

export function useCreateProduct() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["product", "create"],
    (data: {
      isSales: boolean;
      title: string;
      type: string;
      modal?: string;
      brandId: number;
      years: number;
      price: number;
      tags: string[];
      imageIds: string[];
      coverImageId: number;
      highlight: string;
      intro?: string;
      spec?: string;
      story?: string;
      warranty?: string;
      comment?: string;
    }) => {
      return axios.post(`/backend/merchants/${merchantStore.selectedMerchant}/cars`, data);
    },
    {
      onSuccess: () => {
        message.success("新增成功");
        queryClient.invalidateQueries("products");
      },
    }
  );
}

export function useUpdateProduct() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["product", "update"],
    (data: {
      id: string;
      isSales: boolean;
      title: string;
      type: string;
      modal?: string;
      brandId: number;
      years: number;
      price: number;
      tags: string[];
      imageIds: string[];
      coverImageId: number;
      highlight: string;
      intro?: string;
      spec?: string;
      story?: string;
      warranty?: string;
      comment?: string;
    }) => {
      return axios.patch(`/backend/merchants/${merchantStore.selectedMerchant}/cars/${data.id}`, data);
    },
    {
      onSuccess: () => {
        message.success("更新成功");
        queryClient.invalidateQueries("products");
      },
    }
  );
}

export function useCarBrandList() {
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();

  return useQuery(["carBrandList", merchantStore.selectedMerchant], async () => {
    if (!authStore.token || !merchantStore.selectedMerchant) return;

    const response = await axios.get<{ brands: [] }>(`/backend/merchants/${merchantStore.selectedMerchant}/car-brands`);

    return response.data;
  });
}

export function useCreateCarBrand() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["carBrand", "create"],
    (data: { brand: string }) => {
      return axios.post(`/backend/merchants/${merchantStore.selectedMerchant}/car-brands`, data);
    },
    {
      onSuccess: () => {
        // message.success("新增成功");
        queryClient.invalidateQueries("carBrandList");
      },
    }
  );
}

export function useCreateCarType() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["carBrand", "create"],
    (data: { brandId: number; type: string }) => {
      return axios.post(
        `/backend/merchants/${merchantStore.selectedMerchant}/car-brands/${data.brandId}/car-types`,
        data
      );
    },
    {
      onSuccess: () => {
        // message.success("新增成功");
        queryClient.invalidateQueries("carBrandList");
      },
    }
  );
}

export function useCarModalList() {
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();

  return useQuery(["carModalList", merchantStore.selectedMerchant], async () => {
    if (!authStore.token || !merchantStore.selectedMerchant) return;

    const response = await axios.get<{ modals: [] }>(`/backend/merchants/${merchantStore.selectedMerchant}/car-modals`);

    return response.data;
  });
}

export function useCreateCarModal() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["carModal", "create"],
    (data: { modal: string }) => {
      return axios.post(`/backend/merchants/${merchantStore.selectedMerchant}/car-modals`, data);
    },
    {
      onSuccess: () => {
        // message.success("新增成功");
        queryClient.invalidateQueries("carModalList");
      },
    }
  );
}

export function useCarTagList() {
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();

  return useQuery(["carTagList", merchantStore.selectedMerchant], async () => {
    if (!authStore.token || !merchantStore.selectedMerchant) return;

    const response = await axios.get<{ tags: [] }>(`/backend/merchants/${merchantStore.selectedMerchant}/car-tags`);

    return response.data;
  });
}

export function useCreateCarTag() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["carTag", "create"],
    (data: { tag: string }) => {
      return axios.post(`/backend/merchants/${merchantStore.selectedMerchant}/car-tags`, data);
    },
    {
      onSuccess: () => {
        // message.success("新增成功");
        queryClient.invalidateQueries("carTagList");
      },
    }
  );
}
