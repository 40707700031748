import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { first, map } from "lodash";
import axios from "axios";
import { MenuProps } from "rc-menu";
import { Avatar, Dropdown, Form, Layout, Menu, Select, Space } from "antd";
import { LogoutOutlined, SettingOutlined, ProductOutlined, CalendarOutlined } from "@ant-design/icons";
import { Content, Header } from "antd/es/layout/layout";
import { useForm } from "antd/es/form/Form";

import "./App.css";

import { PrivateRoute, PublicRoute } from "./route";
import { useAuthStore } from "./stores/useAuthStore";
import { useMerchantStore } from "./stores/useMerchantStore";

import { useAuthInfo } from "./hooks/apis/auth";

import { Login } from "./pages/login/Login";
import { UserList } from "./pages/settings/user/UserList";
import { ProductList } from "./pages/products/ProductList";
import { ReservationList } from "./pages/reservations/ReservationList";
import { ReservationDetailList } from "./pages/products/ReservationDetailList";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label: children ? (
      label
    ) : (
      <Link to={key.toString()} key={key}>
        {label}
      </Link>
    ),
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("商品管理", "/products", <ProductOutlined />),
  getItem("預約紀錄管理", "/reservations", <CalendarOutlined />),
  getItem("系統設定", "/settings", <SettingOutlined />, [getItem("用戶管理", "/settings/users")]),
];

const App: React.FC = () => {
  const location = useLocation();
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [collapsed, setCollapsed] = useState(true);
  const [form] = useForm<{
    merchantId: number;
  }>();

  const { mutateAsync: getAuthInfo } = useAuthInfo();

  useLayoutEffect(() => {
    if (token && !authStore.token) {
      authStore.login(token);
    }
  }, [token, authStore.login, authStore, authStore.token]);

  useLayoutEffect(() => {
    if (authStore.token) {
      getAuthInfo();

      axios.interceptors.request.clear();
      axios.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${authStore.token}`;

        return config;
      });
    }
  }, [authStore.token, getAuthInfo]);

  useLayoutEffect(() => {
    axios.interceptors.response.clear();

    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 401) {
          authStore.logout();
        }

        throw error;
      }
    );
  }, [authStore, authStore.logout]);

  const merchantId = Form.useWatch("merchantId", form);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useLayoutEffect(() => {
    if (merchantId && merchantStore.selectedMerchant !== merchantId) {
      merchantStore.setMerchant(merchantId);
    }
  }, [merchantStore, merchantId]);

  const merchantOptions = useMemo(
    () =>
      map(authStore.authInfo.merchants, (item: { name: string; id: number }) => ({
        label: item.name,
        value: String(item.id),
      })),
    [authStore.authInfo.merchants]
  );

  return (
    <Layout className="min-h-[100vh] max-h-[100vh] h-[100vh]">
      {authStore.token && (
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
          <Menu theme="dark" mode="inline" items={items} selectedKeys={[location.pathname]} />
        </Sider>
      )}
      <Layout>
        {authStore.token && (
          <Header className="flex justify-between items-center px-4">
            <span className="flex items-center text-2xl font-bold text-gray-200">
              主控台
              <MerchantSelector form={form} merchantOptions={merchantOptions} />
            </span>

            <Dropdown
              menu={{
                items: [
                  {
                    key: "logout",
                    icon: <LogoutOutlined />,
                    label: "登出",
                    onClick: authStore.logout,
                  },
                ],
              }}
            >
              <Space>
                <Avatar size="large" src={authStore.user?.photoUrl} className="bg-gray-200 text-[#001529]">
                  {first(authStore.user?.displayName?.split(""))}
                </Avatar>
                <span className="text-gray-200">{authStore.user?.displayName}</span>
              </Space>
            </Dropdown>
          </Header>
        )}
        <Content className="p-4 overflow-auto">
          <Routes>
            <Route element={<PrivateRoute />}>
              {/* <Route path="products" element={<ProductList />} /> */}
              <Route
                path="products"
                children={[
                  <Route index key="index" element={<ProductList />} />,
                  <Route key="reservation" path="reservation/:id" element={<ReservationDetailList />} />,
                  <Route path="*" key="default" element={<Navigate to="" replace />} />,
                ]}
              />
              <Route path="reservations" element={<ReservationList />} />
              <Route
                path="settings"
                children={[
                  <Route index key="index" element={<Navigate to="users" replace />} />,
                  <Route key="users" path="users" element={<UserList />} />,
                  <Route path="*" key="default" element={<Navigate to="" replace />} />,
                ]}
              />
              <Route path="*" element={<Navigate to="settings" replace />} />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path="login" element={<Login />} />
            </Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;

const MerchantSelector = React.memo(
  (props: { form: any; merchantOptions: Array<{ label: string; value: string }> }) => {
    return (
      (props.merchantOptions?.length > 0 && (
        <Form form={props.form} autoComplete="off" initialValues={{ merchantId: props.merchantOptions[0]?.value }}>
          <Form.Item className="ml-5 mb-0" name="merchantId" rules={[{ required: true, message: "請選擇商戶" }]}>
            <Select placeholder="請選擇商戶" options={props.merchantOptions} />
          </Form.Item>
        </Form>
      )) ||
      null
    );
  }
);
