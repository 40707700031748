import { Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { map } from "lodash";
import { useListRoles } from "../hooks/apis/role";

type Props = {
  multiple?: boolean;
  required?: boolean;
};

export function RoleSelector(props: Props) {
  const { data: { roles } = {}, isLoading, isFetching } = useListRoles();

  return (
    <FormItem
      label="角色"
      name={props.multiple ? "roleIds" : "roleId"}
      rules={[{ required: props.required, message: "請選擇角色" }]}
    >
      <Select
        loading={isLoading || isFetching}
        mode={props.multiple ? "multiple" : undefined}
        optionFilterProp="label"
        options={map(roles, role => ({ label: role.name, value: role.id })) || []}
        placeholder="請選擇角色"
      />
    </FormItem>
  );
}
