import { map } from "lodash";

export enum RESERVATION_STATE {
  PENDING = "PENDING",
  CONCATED = "CONCATED",
  DONE = "DONE",
}

export const RESERVATION_STATE_LABEL = {
  [RESERVATION_STATE.PENDING]: "等待回覆",
  [RESERVATION_STATE.CONCATED]: "已聯絡",
  [RESERVATION_STATE.DONE]: "完成",
};

export const RESERVATION_STATE_OPTIONS = map(RESERVATION_STATE, (value, key) => ({
  label: RESERVATION_STATE_LABEL[value],
  value,
}));

export enum VIEWING_TYPE {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export const VIEWING_TYPE_LABEL = {
  [VIEWING_TYPE.ONLINE]: "線上",
  [VIEWING_TYPE.OFFLINE]: "線下",
};
