import { useEffect } from "react";
import { Button, Col, Form, Radio, Row, Select, Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form/Form";
import Input from "antd/es/input/Input";
import { filter, keys, map } from "lodash";
import QuillEditor from "react-quill";

import "react-quill/dist/quill.snow.css";

const MAX_IMAGE_SIZE = 10;

const COVER_OPTIONS = map(Array(MAX_IMAGE_SIZE).fill(""), (_, index) => ({
  label: index + 1,
  value: index + 1,
}));

type ProductProps = {
  form: FormInstance;
  product?: {
    id: number;
    merchantId: number;
    isSales: boolean;
    // brand: {
    //   id: number;
    //   name: string;
    //   types: string[];
    // };
    brandId: string | number;
    type: string;
    modal: {};
    title: string;
    years: number;
    price: number;
    tags: string[];
    imageUrl: string;
    creator: {
      id: number;
      name: {};
      email: string;
    };
    imageIds: [];
  };
  formOptions: { brand: any; modal: any; tag: any };
};

export function ProductForm(props: ProductProps) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  const brandId = Form.useWatch("brandId", props.form);
  const imageIds = Form.useWatch("imageIds", props.form);

  const getFile = () => {
    return props.form.getFieldValue("imageIds") || [];
  };

  return (
    <Form className="product-form" form={props.form} initialValues={props.product || {}}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Row gutter={12}>
        <Col span="12">
          <Form.Item label="是否上架" name="isSales" rules={[{ required: true, message: "請輸入是否上架" }]}>
            <Radio.Group
              options={[
                { label: "上架", value: true },
                { label: "下架", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item label="標題" name="title" rules={[{ required: true, message: "請輸入標題" }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="車廠"
            name="brandId"
            rules={[
              { required: true, message: "請選擇車廠" },
              {
                validator: async (rule, value) => {
                  const isNewBrand =
                    filter(Object.values(props.formOptions.brand), (item: any) => String(item.id) === brandId[0])
                      .length === 0;

                  if (isNewBrand && /^[0-9]+$/.test(value[0])) {
                    throw new Error("不允許純數字");
                  }
                },
              },
            ]}
          >
            <Select
              mode="tags"
              maxCount={1}
              options={map(keys(props.formOptions.brand), (brandKey: any) => ({
                label: props.formOptions.brand[brandKey].name,
                value: String(props.formOptions.brand[brandKey].id),
              }))}
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              onChange={value => {
                props.form.setFieldValue("brandId", value);
                props.form.setFieldValue("type", null);
              }}
            />
          </Form.Item>

          <Form.Item label="車款" name="type" rules={[{ required: true, message: "請選擇車款" }]}>
            <Select
              mode="tags"
              maxCount={1}
              options={map(props.formOptions.brand[brandId]?.types || [], (brandType: string) => ({
                label: brandType,
                value: brandType,
              }))}
            />
          </Form.Item>

          <Form.Item label="車種" name="modal" rules={[{ required: true, message: "請輸入車種" }]}>
            <Select mode="tags" maxCount={1} options={props.formOptions.modal} />
          </Form.Item>

          <Form.Item label="標籤" name="tags">
            <Select mode="tags" options={props.formOptions.tag} />
          </Form.Item>

          <Form.Item
            label="出廠年份"
            name="years"
            rules={[
              { required: true, message: "請輸入出廠年份" },
              {
                validator: async (rule, value) => {
                  value = String(Number(value));
                  if (/^[0-9]+\.[0-9]+$/.test(value)) {
                    throw new Error("不允許小數");
                  } else if (!/^\d{4}$/.test(value)) {
                    throw new Error("年份需為4位數");
                  }
                },
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="價錢"
            name="price"
            rules={[
              { required: true, message: "請輸入價錢" },
              {
                validator: async (rule, value) => {
                  value = String(Number(value));

                  if (!(value > 0)) {
                    throw new Error("價錢需大於1");
                  } else if (/^[0-9]+\.[0-9]+$/.test(value)) {
                    throw new Error("不允許小數");
                  } else if (!/^\d{1,9}$/.test(value)) {
                    throw new Error("位數需小於9位");
                  }
                },
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="照片"
            name="imageIds"
            valuePropName="imageIds"
            getValueFromEvent={getFile}
            rules={[
              {
                required: true,
                message: "請選擇照片",
              },
              () => ({
                async validator(_, value) {
                  let element: File;

                  if (value.length > MAX_IMAGE_SIZE) {
                    return Promise.reject(new Error(`照片總數需小於${MAX_IMAGE_SIZE}張`));
                  }

                  for (let index = 0; index < value.length; index++) {
                    element = value[index];
                    // const imageInfo: any = await loadImage(element);

                    if (element instanceof File) {
                      if (element.size > 1048576) {
                        return Promise.reject(new Error(`檔案太大，需小於 1MB（${element.name}）`));
                      }
                    } else if (!("url" in element)) {
                      return Promise.reject(new Error(`檔案讀取有誤`));
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Upload
              listType="picture-card"
              multiple
              beforeUpload={async (file: File) => {
                const imageList = props.form.getFieldValue("imageIds") || [];
                props.form.setFieldValue("imageIds", imageList.concat(file));

                // 因為默認會有action 所以一定要 return false 不然會404
                return false;
              }}
              onPreview={onPreview}
              onRemove={(file: UploadFile) => {
                const imageList = props.form.getFieldValue("imageIds") || [];
                props.form.setFieldValue(
                  "imageIds",
                  imageList.filter((item: UploadFile) => item.uid !== file.uid)
                );
              }}
              defaultFileList={imageIds}
            >
              <Button icon={<UploadOutlined />}>選擇</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="封面照"
            name="coverImageId"
            // tooltip="請選擇上方的第幾張照片要做為封面照"
            extra="請選擇上方的第幾張照片要做為封面照"
            rules={[
              { required: true, message: "請選擇封面照" },
              {
                validator: async (rule, value) => {
                  const imageListLength = (props.form.getFieldValue("imageIds") || [])?.length;
                  if (value > imageListLength) {
                    throw new Error("無效的照片編號");
                  }
                },
              },
            ]}
          >
            <Select options={COVER_OPTIONS} />
            {/* <Select options={COVER_OPTIONS} suffixIcon={<p>張照片為封面照</p>} /> */}
          </Form.Item>

          <Form.Item label="重點文字" name="highlight" rules={[{ required: true, message: "請填寫重點文字" }]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span="12">
          <Form.Item label="車輛介紹" name="intro">
            {/* @ts-ignore */}
            <TextEditor />
          </Form.Item>

          <Form.Item label="規格&配備" name="spec">
            {/* @ts-ignore */}
            <TextEditor />
          </Form.Item>

          <Form.Item label="車主故事" name="story">
            {/* @ts-ignore */}
            <TextEditor />
          </Form.Item>

          <Form.Item label="保固說明" name="warranty">
            {/* @ts-ignore */}
            <TextEditor />
          </Form.Item>

          <Form.Item label="MY CHA點評" name="comment">
            {/* @ts-ignore */}
            <TextEditor />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const onPreview = async (file: UploadFile) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as FileType);
      reader.onload = () => resolve(reader.result as string);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};

const toolbarOptions = [
  [
    "bold",
    "italic",
    "underline",
    "strike",
    "link",
    { list: "ordered" },
    { list: "bullet" },
    { color: [] },
    { background: [] },
    { header: [1, 2, 3, 4, 5, 6, false] },
    "clean", // remove formatting button
  ],
];

const TextEditor: React.FC<{
  value: string;
  placeholder: string;
  onChange: (e: any) => void;
}> = ({ value, onChange, placeholder }) => {
  return (
    <div className="text-editor">
      <QuillEditor
        value={value || ""}
        onChange={onChange}
        placeholder={placeholder}
        modules={{
          toolbar: toolbarOptions,
        }}
      />
    </div>
  );
};
