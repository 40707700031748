import { create } from "zustand";

type MerchantStore = {
  setMerchant: (id: number) => void;
  selectedMerchant: number | null;
};

export const useMerchantStore = create<MerchantStore>()(set => ({
  setMerchant: (id: number) => set({ selectedMerchant: id }),
  selectedMerchant: null,
}));
