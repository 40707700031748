import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { message } from "antd";
import { useAuthStore } from "../../stores/useAuthStore";
import { useMerchantStore } from "../../stores/useMerchantStore";

export function useReservations(data: { skip?: number; take?: number }) {
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();

  return useQuery(["reservations", data, merchantStore.selectedMerchant], async () => {
    if (!authStore.token || !merchantStore.selectedMerchant) return;

    const response = await axios.get<{
      reservations: {
        bookingTimes: number[];
        car: {
          id: number;
          title: string;
          imageUrl: string;
          brand: string;
          modal: string;
          price: number;
          type: string;
          years: number;
        };
        carId: number;
        coordinatorName?: string;
        createdAt: number;
        id: number;
        location?: string;
        merchantId: number;
        name: string;
        phone: string;
        salerId?: string;
        status: "PENDING" | "CONCATED" | "DONE";
        viewingType: "ONLINE" | "OFFLINE";
      }[];
      total: number;
    }>(`/backend/merchants/${merchantStore.selectedMerchant}/reservations`, {
      params: data,
    });

    return response.data;
  });
}

export function useReservationDetail(data: { carId: number; skip?: number; take?: number }) {
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();

  return useQuery(["reservationDetail", data, merchantStore.selectedMerchant], async () => {
    if (!authStore.token || !merchantStore.selectedMerchant) return;

    const response = await axios.get<{
      reservations: {
        bookingTimes: number[];
        car: {
          id: number;
          title: string;
          imageUrl: string;
          brand: string;
          modal: string;
          price: number;
          type: string;
          years: number;
        };
        carId: number;
        coordinatorName?: string;
        createdAt: number;
        id: number;
        location?: string;
        merchantId: number;
        name: string;
        phone: string;
        salerId?: string;
        status: "PENDING" | "CONCATED" | "DONE";
        viewingType: "ONLINE" | "OFFLINE";
      }[];
      total: number;
    }>(`/backend/merchants/${merchantStore.selectedMerchant}/cars/${data.carId}/reservations`, {
      params: data,
    });

    return response.data;
  });
}

export function useUpdateReservationDetail() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["product", "update"],
    (data: {
      id: number;
      bookingTimes: number[];
      coordinatorName?: string;
      location?: string;
      status: "PENDING" | "CONCATED" | "DONE";
      viewingType: "ONLINE" | "OFFLINE";
    }) => {
      return axios.patch(`/backend/merchants/${merchantStore.selectedMerchant}/reservations/${data.id}`, data);
    },
    {
      onSuccess: () => {
        message.success("更新成功");
        queryClient.invalidateQueries("reservations");
      },
    }
  );
}
