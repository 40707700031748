import { useQuery } from "react-query";
import axios from "axios";

export function useListRoles() {
  return useQuery("roleList", async () => {
    const response = await axios.get<{
      roles: {
        id: number;
        isProtected: boolean;
        name: string;
        permissions: Array<{
          id: string;
          name: string;
          desc?: string;
        }>;
      }[];
    }>("/backend/admin/roles");

    return response.data;
  });
}
