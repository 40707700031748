import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { message } from "antd";
import { useAuthStore } from "../../stores/useAuthStore";
import { useMerchantStore } from "../../stores/useMerchantStore";

export function useUsersList() {
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();

  return useQuery(["userList", authStore.token, merchantStore.selectedMerchant], async () => {
    if (!authStore.token || !merchantStore.selectedMerchant) return;

    const response = await axios.get<{ users: []; total: number }>(
      `/backend/merchants/${merchantStore.selectedMerchant}/users`
    );

    return response.data;
  });
}

export function useUsers(data: {
  skip?: number;
  take?: number;
  keyword?: string;
  state?: ["ACTIVATED" | "REVIEWING" | "SUSPENDED"] | undefined;
}) {
  const authStore = useAuthStore();
  const merchantStore = useMerchantStore();

  return useQuery(["users", data, merchantStore.selectedMerchant], async () => {
    if (!authStore.token || !merchantStore.selectedMerchant) return;

    const response = await axios.get<{ users: []; total: number }>(
      `/backend/merchants/${merchantStore.selectedMerchant}/users`,
      {
        params: data,
      }
    );

    return response.data;
  });
}

export function useCreateUser() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["user", "create"],
    (data: {
      displayName: string;
      email: string;
      roles: [{ id: number; desc: string; name: string }];
      username?: string;
    }) => {
      return axios.post(`/backend/merchants/${merchantStore.selectedMerchant}/users`, data);
    },
    {
      onSuccess: () => {
        message.success("新增成功");
        queryClient.invalidateQueries("users");
      },
    }
  );
}

export function useUpdateUser() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["user", "update"],
    (data: {
      id: number;
      displayName: string;
      email: string;
      roles: [{ id: number; desc: string; name: string }];
      state: string;
      username?: string;
    }) => {
      return axios.patch(`/backend/merchants/${merchantStore.selectedMerchant}/users/${data.id}`, data);
    },
    {
      onSuccess: () => {
        message.success("更新成功");
        queryClient.invalidateQueries("users");
      },
    }
  );
}

export function useSuspendUser() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["user", "suspend"],
    (userId: number) => {
      return axios.patch(`/backend/merchants/${merchantStore.selectedMerchant}/users/${userId}/suspend`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        message.success("停權成功");
      },
    }
  );
}

export function useUnsuspendUser() {
  const merchantStore = useMerchantStore();
  const queryClient = useQueryClient();

  return useMutation(
    ["user", "unsuspend"],
    (userId: number) => {
      return axios.patch(`/backend/merchants/${merchantStore.selectedMerchant}/users/${userId}/unsuspend`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        message.success("解除停權成功");
      },
    }
  );
}
